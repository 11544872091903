<template>
    <div class="contaners">
        <img src="../../assets/content_img.png" />
        <div class="content">
            <div class="header_btn">入会申请书</div>
            <div class="content_box">
                <div class="srow">
                    <img :src="configImg"/>
                </div>
            </div>
            <div class="nextBtn" @click="nextBtn">下一步</div>
        </div>
       
    </div>
</template>
<script type="">
import {configSetting} from "@/api/common";
    export default{
        data(){
            return{
                value: '',
                configImg:''
            }
        },
        created(){
            document.title="入会申请";
            if(localStorage.getItem("temp2")){
                console.log(1)
              localStorage.removeItem("temp2");
            }
            if(localStorage.getItem("imgName")){
              localStorage.removeItem("imgName");
            }
            if(localStorage.getItem("img")){
              localStorage.removeItem("img");
            }
            this.getConfig()
        },
        methods:{
            nextBtn(){
                console.log(1)
                this.$router.push("/lador/signature")
            },
            getConfig(){
                let data = {
                    config:["union_member_application"]
                }
                configSetting(data).then(res =>{
                    if(res.code==0){
                        this.configImg = res.data.union_member_application
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .header_btn{
        font-size: 50px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        margin: 40px auto;
    }
    .content{
        position: absolute;
        top: 30px;
        left: 0
    }
    .contaners{
        width: 100%;height: 100vh
    }
    .contaners>img{
        width: 100%;height: 100%;
    }
    .content_box{
        width:645px;
        /* height: 800px; */
        /* border: 2px solid #FFFFFF; */

        border-radius: 20px;
        margin: 0 53px;
        /* padding: 17px 24px;box-sizing: border-box; */
        color: #fff;
        font-size: 25px;
    }
    .content_box div{
        /* margin-bottom: 30px; */
        line-height: 40px;
        letter-spacing: 3px
    }
    .content_box img{
        width:100%;
        /* height:100%; */
    }

    .nextBtn{
        width: 205px;
        height: 64px;
        background: #FFFFFF;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 41px;
        text-align: center;
        line-height:64px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #2A7BB5;
        opacity: 1;
        margin: 51px auto 0;
    }

    .srow{
        height: 800px;
        overflow-y:auto;
    }
</style>
